//$primary_color: #090;
$primary_color:#008642;
//$primary_color:#000;
$ginger_color:#bf5700;
$red_color:#bf0000;
$lightblue_color:#007373;
//$lightyellow_color:#fff1bc;
//$lightyellow_color:#686262;
//$lightyellow_color:#d2d2d2;
$lightyellow_color:#f5f5f5;
$lightyellow_button:#f0e8cc;
$lightgrey_color:#c7c7c7;
$primary_background:#dadada;
$hard_grey:#a5a5a5;
//$active_link:#3c0;
$active_link:#00964a;
$active_hover:#8ace73;
$grey_border:#e1e1e1;
$on_primary_color:#fff;
$secondary_background:#fff;
$default_color:#404148;
$dark_grey:#292c2d;
$text_color: #222;
$register_link_color:#ca4646;
$login_link_color:#1A28B3;
$button_hover:#006130;
$grey_zebra: #f5f5f5;
$logo_size: 90px;
$p_top: 10px;
$m_top:20px;
$main-font-size:14px;
$menu_font_size:16px;
$map_height:500px;